body, html {
  height: 100%;
  margin: 0;
  /*overflow: scroll;*/

  background-color: #121212;
  color: #A8DBA8;
  font-family: 'Minecraft', Arial, sans-serif;
  justify-content: center;
}

.App {
  margin-top: 100px;
  height: 100vh;
  text-align: center;
  background-color: #121212;
  color: #A8DBA8;
  font-family: 'Minecraft', Arial, sans-serif;
  justify-content: center;
}

.App h1 {
  padding-top: 20vh;
  font-size: 60px;
}

.fontOutline {
  text-shadow: -3px -3px 0 #006500, 3px -3px 0 #006500, -3px 3px 0 #006500, 3px 3px 0 #006500;
}

.fontOutlineThin {
  text-shadow: -2px -2px 0 #006500, 2px -2px 0 #006500, -2px 2px 0 #006500, 2px 2px 0 #006500;
}

.fontOutlineWhite {
  text-shadow: -1px -1px 0 #a0a0a0, 1px -1px 0 #a0a0a0, -1px 1px 0 #a0a0a0, 1px 1px 0 #a0a0a0;
}

.goodColors {
  color: #A8DBA8;
  color: #006500;

  background-color: #121212;
  background-color: #121212;

  background-color: #d28818;
  background-color: #f5a623;

  background-color: #36827f;
  background-color: #2b6864;
}

@font-face {
  font-family: "Minecraft";
  src: url('/public/fonts/MinecraftRegular-Bmg3.otf');
}
@font-face {
  font-family: "Minecraft Bold";
  src: url('/public/fonts/MinecraftBold-nMK1.otf');
}

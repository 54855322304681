.user-account {
    display: flex;
    flex-direction: column;
    align-items: center;
    /*margin-bottom: 20px;*/
}

.user-account img {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
}

.shop {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
    gap: 20px;
}

.shop-card {
    border-radius: 10px;
    background-color: rgba(90, 105, 112, 0.15);
    padding: 20px;
    box-sizing: border-box;
    margin-bottom: 20px;
    transition: transform 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.shop-card .card-bottom {
    text-align: center;
}

.shop-card .delete-button {
    background-color: red;
    border-radius: 100px;
    min-width: 30px;
    font-size: 0.8em;
    padding: 2px 10px;
}

.shop-card:hover {
    transform: scale(1.03);
}

.shop-card h2 {
    margin: 0 0 15px;
    font-size: 1.5em;
}

.shop-card p {
    margin: 0 0 15px;
    color: #c8e6c8;
}

.shop-card .shop-cost {
    font-size: 1em;
    margin: 0;
    padding: 2px 0 5px;
    font-family: "Minecraft Bold";
    color: #ffd500;
}

.shop-cost.crossed-out {
    text-decoration: line-through;
    color: #ff6a00;
}


.shop-card button {
    background-color: #252e48;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.shop-card button:hover {
    background-color: #1c2236;
}

/* Rarity Colors */
.shop-card.Common {
    border: 2px solid #49b0ce;
}

.shop-card.Uncommon {
    border: 2px solid #14c914;
}

.shop-card.Rare {
    border: 2px solid #1616c9;
}

.shop-card.Epic {
    border: 2px solid #7111a1;
}

.shop-card.Legendary {
    border: 2px solid #ffd500;
}

.shop-card.Mythic { /* Not currently added to database */
    border: 2px solid #ff6a00;
}
.titleImage {
    width: 100vw;
    height: 420px;
    z-index: 3;
    position: absolute;
    top: 0;
    overflow: clip;
}

.title {
    padding: 0;
    margin: 0;
    top: 300px;
    position: absolute;
    z-index: 5;
    font-size: 5em;
    width: 100%;
}

.details-list {
    width: 60%;
    text-align: left;
    margin: 30px auto 30px auto;
}

.details-list li {
    margin: 10px;
    font-weight: bold;
}

@media screen and (max-width: 1100px) {
    .title {
        font-size: 3em;
    }
    .details-list {
        width: 100%;
    }
}


.titleImage .image {
    background: url("/public/images/general/coverImage.png") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100%;
    min-width: 100%;
    aspect-ratio: 1.6;

    position: absolute;
    top: 0;
}

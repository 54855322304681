.floating-cube {
    position: fixed;
    width: 60px;
    height: 60px;
    perspective: 1000px;

    z-index: 0;
}

.cube {
    width: 100%;
    height: 100%;
    position: relative;
    transform-style: preserve-3d;
    transform: rotateZ(30deg) rotateY(45deg);
    animation: rotate 30s linear infinite;
}
.cube .face {
    position: absolute;
    width: 60px;
    height: 60px;
    background: transparent;
    border: 1px solid #A8DBA8;
    box-shadow: 0 0 10px rgba(0,0,0,0.3); /* This will add a shadow to the cube faces */
}

.front { transform: rotateY(0deg) translateZ(30px); }
.back { transform: rotateX(180deg) translateZ(30px); }
.right { transform: rotateY(90deg) translateZ(30px); }
.left { transform: rotateY(-90deg) translateZ(30px); }
.top { transform: rotateX(90deg) translateZ(30px); }
.bottom { transform: rotateX(-90deg) translateZ(30px); }

@media screen and (max-width: 880px) {
    .floating-cube {
        display: none;
    }
}

@media screen and (max-width: 880px) {
    .floating-cube {
        display: none;
    }
}

@keyframes float {
    0% { transform: translateY(0px); }
    50% { transform: translateY(-20px); }
    100% { transform: translateY(0px); }
}